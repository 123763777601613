import { Component, Vue, Watch } from 'vue-property-decorator';
import { DateTime, Settings } from 'luxon';
import { firstDayOfWeek, lastDayOfWeek, formatDate } from '@/support/String';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';
import { User } from '@/models/User';
import { Event } from '@/models/Event';
import { cloneDeep, debounce } from 'lodash';
import { TableMeta } from '@/components/data-table/DataTable';
import { DateFormatter } from '@/support/DateFormatter';
import { filter } from '@/support/Utilities';

@Component<AvailabilityLog>({})
export default class AvailabilityLog extends Vue {
  public $pageTitle = 'Beschikbaarheidslog';

  protected date: DateTime = DateTime.local();

  protected organizations: Organization[] | null = null;

  protected logs: Log[] = [];

  protected activeTab = '';

  protected isLoading = true;

  // filters
  protected weekdate = new DateFormatter();

  protected eventDate = new DateFormatter();

  protected experts: User[] = [];

  protected users: User[] = [];

  protected selectedExpert: string | null = '';

  protected selectedUser: string | null = '';

  protected expertSearch = '';

  protected userSearch = '';

  protected modification = '';

  protected debouncedSearchExpert: Function = debounce(this.getExperts, 300);

  protected debouncedSearchUser: Function = debounce(this.getUsers, 300);

  // Pagination
  protected total = 0;

  protected lastPage = 1;

  protected from = 0;

  protected to = 0;

  protected page = 1;

  protected limit = 15;

  public paginationDebounce: Function = this.handlePagination();

  public mounted() {
    Settings.defaultLocale = 'nl';
    this.emitBreadcrumb();
    this.initialize();
  }

  protected initialize() {
    if (this.$store.state.isServiceOrganization) {
      this.getOrganizations();
    } else {
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      this.setActiveTab(this.organizations);
    }
  }

  protected getOrganizations() {
    new Organization()
      .all()
      .then((organizations: Organization[]) => {
        if (organizations && organizations.length && organizations[0].name) {
          this.setActiveTab(organizations);
        }

        this.organizations = organizations;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async setActiveTab(organizations: Organization[]) {
    this.activeTab = organizations[0].id as string;
    await this.getAvailabilityLogs(organizations[0].id as string);
    await this.getExperts();
    await this.getUsers();
  }

  protected async getExperts() {
    this.experts = await new User()
      .filter({
        search: this.expertSearch,
      })
      .dmz(this.activeTab)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async getUsers() {
    this.users = await new User()
      .filter({
        search: this.userSearch,
        is_planner: true,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getAvailabilityLogs(id: string) {
    const payload: AvailabilityLogPayload = {};
    payload.week = this.weekdate.selectedDate || null;
    payload.user = this.selectedUser || null;
    payload.expert = this.selectedExpert || null;
    payload.event_week = this.eventDate.selectedDate || null;
    payload.modification = this.modification || null;
    filter(payload);

    new User()
      .include(['user', 'event'])
      .dmz(id)
      .limit(this.limit)
      .page(this.page)
      .filter(payload)
      .eventChangeLog()
      .then((logs: Log[]) => {
        this.logs = logs;
        this.total = logs[0] !== undefined ? (logs[0].meta as TableMeta).total : 0;
        this.lastPage = logs[0] !== undefined ? (logs[0].meta as TableMeta).last_page : 1;
        this.from = logs[0] !== undefined ? (logs[0].meta as TableMeta).from : 0;
        this.to = logs[0] !== undefined ? (logs[0].meta as TableMeta).to : 0;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected switchTab(id: string) {
    this.resetFilters();
    this.getAvailabilityLogs(id);
  }

  protected resetFilters() {
    this.selectedExpert = null;
    this.selectedUser = null;
    this.weekdate.selectedDate = null;
  }

  protected eventTypeLookup: {[key: string] : any} = {
    free: {
      class: 'availability__log--available',
      name: 'Beschikbaar',
    },
    busy: {
      class: 'availability__log--not-available',
      name: 'Niet Beschikbaar',
    },
    internal: {
      class: 'availability__log--bold',
      name: 'Intern beschikbaar',
    },
    sick: {
      class: 'availability__log--bold',
      name: 'Ziek',
    },
    other: {
      class: 'availability__log--bold',
      name: 'overig',
    },
    appointment: {
      class: 'availability__log--bold',
      name: 'Afspraak',
    },
    meeting: {
      class: 'availability__log--bold',
      name: 'Overleg',
    },
    house_visit: {
      class: 'availability__log--bold',
      name: 'Huisbezoek',
    },
    speaking_room: {
      class: 'availability__log--bold',
      name: 'Spreekkamer dienst',
    },
    holiday: {
      class: 'availability__log--bold',
      name: 'Verlof',
    },
    loss_of_non_residential_property_value: {
      class: 'availability__log--bold',
      name: 'Waardedaling niet woningen',
    },
    manure_cellar: {
      class: 'availability__log--bold',
      name: 'Mestkelder',
    },
  };

  protected getStyledLog(log: Log) {
    const event = log.event;
    const logUserName = log.user ? log.user.name : '';
    const eventUserName = event && event.user ? event.user.name : '';
    const eventStartsAt = event && event.starts_at ? DateTime.fromFormat(event.starts_at, 'yyyy-LL-dd HH:mm:ss').toFormat('cccc dd-LL-yyyy') : '';
    const logTypeClass = this.eventTypeLookup[log && log.changed_from ? log.changed_from : 'other'] ? this.eventTypeLookup[log && log.changed_from ? log.changed_from : 'other'].class : '';
    const logTypeName = this.eventTypeLookup[log && log.changed_from ? log.changed_from : 'other'] ? this.eventTypeLookup[log && log.changed_from ? log.changed_from : 'other'].name : '';
    const eventTypeClass = this.eventTypeLookup[event && event.type ? event.type : 'other'] ? this.eventTypeLookup[event && event.type ? event.type : 'other'].class : '';
    const eventTypeName = this.eventTypeLookup[event && event.type ? event.type : 'other'] ? this.eventTypeLookup[event && event.type ? event.type : 'other'].name : '';
    const message = log && (log as any).message ? (log as any).message : '';
    return event ? `<span class="availability__log--bold">${logUserName}</span> heeft de beschikbaarheid van <span class="availability__log--bold">${eventUserName}</span>
    voor <span class="availability__log--bold">${eventStartsAt}</span>
    aangepast van <span class="availability__log--bold ${logTypeClass}">${logTypeName}</span> naar <span class="availability__log--bold ${eventTypeClass}">${eventTypeName}</span>` : message.replace(logUserName, `<span class="availability__log--bold">${logUserName}</span>`);
  }

  protected getDateLabelDays(date: DateTime): string {
    if (! date) {
      return '';
    }
    return `(${firstDayOfWeek(date).toFormat('dd-LL-yyyy')} - ${lastDayOfWeek(date).toFormat('dd-LL-yyyy')})`;
  }

  protected getDateLabelWeek(date: DateTime): number {
    if (! date) {
      return 0;
    }
    return date.weekNumber;
  }

  protected getDateLabelFirstDay(date: DateTime): string {
    if (! date) {
      return '';
    }
    return `${firstDayOfWeek(date).toFormat('dd-LL-yyyy')}`;
  }

  protected getDateLabelLastDay(date: DateTime): string {
    if (! date) {
      return '';
    }
    return `${lastDayOfWeek(date).toFormat('dd-LL-yyyy')}`;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Beschikbaarheidslog' },
        ],
      });
  }

  public handlePagination() {
    return debounce(
      (page: number) => {
        this.page = page;
        this.getAvailabilityLogs(this.activeTab);
      },
      300,
    );
  }

  protected get modifications() {
    return User.modifications;
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

interface Log {
  changed_from?: string;
  changed_to?: string;
  starts_at?: string;
  user?: User;
  event?: Event;
  meta?: TableMeta;
}

interface AvailabilityLogPayload {
  week?: string | null;
  user?: string | null;
  expert?: string | null;
  event_week?: string | null;
  modification?: string | null;
}
