var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Beschikbaarheidslog")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "planning-tab-" + index,
                          staticClass: "tab__filled",
                          class: { active: _vm.activeTab === organization.id },
                          attrs: { href: "#" + organization.id },
                          on: {
                            click: function($event) {
                              _vm.switchTab(organization.id)
                              _vm.isLoading = true
                              _vm.page = 1
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(organization.name) + " ")]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _vm._l(_vm.organizations, function(organization) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key: "planning-tab-content-" + organization.name,
                              attrs: {
                                lazy: true,
                                transition: false,
                                "reverse-transition": false,
                                value: organization.id
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "elementPanel",
                                  attrs: { row: "", wrap: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: { wrap: "", "align-baseline": "" }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "dialog",
                                              refInFor: true,
                                              attrs: {
                                                "close-on-content-click": false,
                                                lazy: "",
                                                "nudge-right": "100",
                                                "full-width": "",
                                                "max-width": "290px",
                                                "min-width": "290px"
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "input input-date",
                                                attrs: {
                                                  slot: "activator",
                                                  placeholder: "DD-MM-YYYY",
                                                  label: "Datum (week)",
                                                  clearable: "",
                                                  "error-messages":
                                                    _vm.weekdate.errorMessage
                                                },
                                                on: {
                                                  "click:clear": function(
                                                    $event
                                                  ) {
                                                    _vm.weekdate.clear()
                                                    _vm.getAvailabilityLogs(
                                                      organization.id
                                                    )
                                                  }
                                                },
                                                slot: "activator",
                                                model: {
                                                  value:
                                                    _vm.weekdate.formattedDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.weekdate,
                                                      "formattedDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "weekdate.formattedDate"
                                                }
                                              }),
                                              _c("v-date-picker", {
                                                ref: "picker",
                                                refInFor: true,
                                                attrs: {
                                                  "first-day-of-week": "1",
                                                  locale: "nl-nl",
                                                  min: "1910-01-01",
                                                  color: "#837f16"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    _vm.page = 1
                                                    _vm.getAvailabilityLogs(
                                                      organization.id
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.weekdate.selectedDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.weekdate,
                                                      "selectedDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "weekdate.selectedDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c(
                                            "v-menu",
                                            {
                                              ref: "dialog",
                                              refInFor: true,
                                              attrs: {
                                                "close-on-content-click": false,
                                                lazy: "",
                                                "nudge-right": "100",
                                                "full-width": "",
                                                "max-width": "290px",
                                                "min-width": "290px"
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "input input-date",
                                                attrs: {
                                                  slot: "activator",
                                                  placeholder: "DD-MM-YYYY",
                                                  label:
                                                    "Datum afspraak (week)",
                                                  clearable: "",
                                                  "error-messages":
                                                    _vm.eventDate.errorMessage
                                                },
                                                on: {
                                                  "click:clear": function(
                                                    $event
                                                  ) {
                                                    _vm.eventDate.clear()
                                                    _vm.getAvailabilityLogs(
                                                      organization.id
                                                    )
                                                  }
                                                },
                                                slot: "activator",
                                                model: {
                                                  value:
                                                    _vm.eventDate.formattedDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.eventDate,
                                                      "formattedDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "eventDate.formattedDate"
                                                }
                                              }),
                                              _c("v-date-picker", {
                                                ref: "picker",
                                                refInFor: true,
                                                attrs: {
                                                  "first-day-of-week": "1",
                                                  locale: "nl-nl",
                                                  min: "1910-01-01",
                                                  color: "#837f16"
                                                },
                                                on: {
                                                  change: function($event) {
                                                    _vm.page = 1
                                                    _vm.getAvailabilityLogs(
                                                      organization.id
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.eventDate.selectedDate,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.eventDate,
                                                      "selectedDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "eventDate.selectedDate"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "with-border",
                                            attrs: {
                                              items: _vm.experts,
                                              "item-text": "name",
                                              "item-value": "uuid",
                                              "hide-details": "",
                                              placeholder: "Deskundige",
                                              "search-input": _vm.expertSearch,
                                              clearable: ""
                                            },
                                            on: {
                                              "update:searchInput": function(
                                                $event
                                              ) {
                                                _vm.expertSearch = $event
                                              },
                                              "update:search-input": function(
                                                $event
                                              ) {
                                                _vm.expertSearch = $event
                                              },
                                              keyup: _vm.debouncedSearchExpert,
                                              change: function($event) {
                                                _vm.page = 1
                                                _vm.getAvailabilityLogs(
                                                  organization.id
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.selectedExpert,
                                              callback: function($$v) {
                                                _vm.selectedExpert = $$v
                                              },
                                              expression: "selectedExpert"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "with-border",
                                            attrs: {
                                              items: _vm.users,
                                              "item-text": "name",
                                              "item-value": "uuid",
                                              "hide-details": "",
                                              placeholder:
                                                "Gebruiker (planner)",
                                              "search-input": _vm.userSearch,
                                              clearable: ""
                                            },
                                            on: {
                                              "update:searchInput": function(
                                                $event
                                              ) {
                                                _vm.userSearch = $event
                                              },
                                              "update:search-input": function(
                                                $event
                                              ) {
                                                _vm.userSearch = $event
                                              },
                                              keyup: _vm.debouncedSearchUser,
                                              change: function($event) {
                                                _vm.page = 1
                                                _vm.getAvailabilityLogs(
                                                  organization.id
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.selectedUser,
                                              callback: function($$v) {
                                                _vm.selectedUser = $$v
                                              },
                                              expression: "selectedUser"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { shrink: "" } },
                                        [
                                          _c("v-select", {
                                            staticClass: "selectFilter input",
                                            attrs: {
                                              label: "Wijzigingen",
                                              items: _vm.modifications,
                                              "item-value": "value",
                                              "item-text": "name",
                                              "hide-details": "",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.getAvailabilityLogs(
                                                  organization.id
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.modification,
                                              callback: function($$v) {
                                                _vm.modification = $$v
                                              },
                                              expression: "modification"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.logs && _vm.logs.length
                                    ? _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _vm._l(_vm.logs, function(log) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      key: log.id,
                                                      staticClass:
                                                        "report-message"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "report-message__block status-change"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "message__meta"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "message__created"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dateFormatTime"
                                                                      )(
                                                                        log.created_at
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c("div", {
                                                            staticClass:
                                                              "message__text",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.getStyledLog(
                                                                  log
                                                                )
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              })
                                            ],
                                            2
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "align-center": "",
                                                    "justify-space-between": "",
                                                    wrap: ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "flex-starving"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(_vm.from) +
                                                            " - " +
                                                            _vm._s(_vm.to) +
                                                            " van " +
                                                            _vm._s(_vm.total)
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "flex-starving"
                                                    },
                                                    [
                                                      _c("v-pagination", {
                                                        attrs: {
                                                          length: _vm.lastPage,
                                                          "total-visible": 7
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.paginationDebounce
                                                        },
                                                        model: {
                                                          value: _vm.page,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.page = $$v
                                                          },
                                                          expression: "page"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.logs.length
                                    ? [
                                        _c("span", [
                                          _vm._v("Geen logs beschikbaar")
                                        ])
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }